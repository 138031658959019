:root {
	--main-light-blue-color: #F0F7FF;
	--main-blue-color: #23ABE2;
	--main-green-color: #8ec73f;
	--light-green-color: #a5d662;
	--main-border-color: #CDD9E6;
	--grid-border-color: #EBF1F6;
	--main-black-color: #253243;
	--main-grey-color: #70818F;
	--main-grey-bg: #70828E;
	--light-grey-color: #97a3ad;
	--main-bg-color: #F4F5F9;
	--box-border-color: #e6ecf2;
	--header-height: 57px;
	--footer-height: 47px;
	--left-panel-width: 60px;
	--side-menu-width: 250px;
	--content-padding: 30px;
	--side-menu-shadow: 3px 0px 20px rgba(0, 0, 0, 0.1);
	--light-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
	--dirty-field-color: #faf0a5;
	--public-pages-bg: #000033;
}
body {
	background-color: var(--main-bg-color);
}
.white-box {
	background-color: white;
	box-shadow: var(--light-box-shadow);
}
.light-box-shadow {
	box-shadow: var(--light-box-shadow);
}
.pull-menu-left {
	left: auto!important;
	right: 0!important;
}
.side-menu-shadow {
	box-shadow: var(--side-menu-shadow);
}
.no-box-shadow {
	box-shadow: none !important;
}
.main-light-blue-bg {
	background-color: var(--main-light-blue-color) !important;
}
.main-light-blue {
	color: var(--main-light-blue-color) !important;
}
.main-blue {
	color: var(--main-blue-color) !important;
}
.main-blue-bg {
	background-color: var(--main-blue-color) !important;
}
.main-blue-border {
	border: 1px solid var(--main-blue-color) !important;
}
.main-black {
	color: var(--main-black-color) !important;
}
.main-green {
	color: var(--main-green-color) !important;
}
.main-green-bg {
	background-color: var(--main-green-color) !important;
}
.light-green {
	color: var(--light-green-color) !important;
}
.light-green-bg {
	background-color: var(--light-green-color) !important;
}
.main-border-color {
	color: var(--main-border-color) !important;
}
.main-black {
	color: var(--main-black-color) !important;
}
.light-grey {
	color: var(--light-grey-color) !important;
}
.main-grey {
	color: var(--main-grey-color) !important;
}
.main-grey-bg {
	background-color: var(--main-grey-bg) !important;
}
.main-green-bg {
	background-color: var(--light-green-color) !important;
}
.main-bg {
	background-color: var(--main-bg-color) !important;
}
.py-50per-rem {
	padding-top: .5rem!important;
	padding-bottom: .5rem!important;
}
.font-size-12 {
	font-size: 12px;
}
.btn-primary{
	color: #fff;
	background-color: var(--main-blue-color);
	border-color: var(--main-blue-color);
}
#wrapper.toggled #sidebar-wrapper {
	width: var(--side-menu-width);
}
#wrapper {
	padding-left: 0;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
	position: relative;
	min-height: 100%;
	height: auto!important;
	margin: 0 auto -40px;
	padding-bottom: 100px !important;
}

#wrapper.toggled.has-left-panel {
	padding-left: calc(var(--side-menu-width) + var(--left-panel-width)) !important;
}

#wrapper.toggled:not(.has-left-panel) {
	padding-left: var(--side-menu-width) !important;
}

#wrapper.has-left-panel:not(.toggled) {
	padding-left: var(--left-panel-width) !important;
}
.box-border {
	border: 1px solid var(--box-border-color);
	border-radius: 5px;
}
.preperty-values > div > div {
	display: flex;
}

.preperty-values > div > div > div {
	border-top: 1px solid var(--box-border-color);
	border-left: 1px solid var(--box-border-color);
	font-weight: bold;
}

.preperty-values > div > div:first-child > div:first-child {
	border-top-left-radius: 10px;
}

.preperty-values > div > div:first-child > div:nth-child(2) {
	border-top-right-radius: 10px;
}

.preperty-values > div > div:last-child > div:first-child {
	border-bottom-left-radius: 10px;
}

.preperty-values > div > div:last-child > div:nth-child(2) {
	border-bottom-right-radius: 10px;
}

.preperty-values > div > div:last-child > div {
	border-bottom: 1px solid var(--box-border-color);
}

.preperty-values > div > div > div:nth-child(2) {
	width: 40%;
	border-right: 1px solid var(--box-border-color);
	background-color: var(--main-bg-color);
	overflow-wrap: break-word;
}

.preperty-values > div > div > div:first-child {
	width: 60%;
	color: var(--main-grey-color);
}

.preperty-values .centered-values > div > div:nth-child(2) {
	text-align: center;
}
.left-menu-extend {
	position: absolute;
	top: 0;
	left: 0;
	width: var(--left-panel-width);
	background-color: var(--main-black-color);
	height: 100%;
}

@media all and (max-width:64em) {
	.no-gutter-left-phone {
		padding-left: 0;
		padding-right: 0
	}
}
.auto-cords {
	top: auto;
	left: auto;
}
.showOnPrint {
	display: none;
}
@media print{
	.showOnPrint{display: block !important;}
	.showOnPrintToggle{display: block !important;}
	.noprint{display: none !important;}
	.page-break-inside{
		page-break-inside: avoid;
	}
}
.pointer {
	cursor: pointer
}
.invalid-input, .invalid-field {
	background-color: #fdd !important;
}
.invalid-input .ng-select-container {
	background-color: #fdd !important;
}
.inner-group-text.input-group > .input-group-prepend > .input-group-text {
	border-right: none;
}
.inner-group-text.input-group > .input-group-prepend + input {
	border-left: none;
}
.dropdown-submenu {
	position: relative
}
.dropdown-submenu .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -1px
}
.blue-hover:hover {
	color: #729ae4;
}
.mat-icon {
	vertical-align: sub;
}
sct-charts {
	width: 100% !important;
}
.mapboxgl-map {
	border-radius: 0.5rem!important;
}
.mapboxgl-marker {
	cursor: pointer !important;
}
.mapboxgl-popup-content {
	min-width: 300px;
	padding: 0.7rem;
	border-radius: 0.5rem!important;
	box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.border-radius{
	border-radius: 0.5rem!important;
}
.border-radius-top{
	border-top-right-radius: 0.5rem!important;
	border-top-left-radius: 0.5rem!important;
}
.border-radius-bottom{
	border-bottom-right-radius: 0.5rem!important;
	border-bottom-left-radius: 0.5rem!important;
}
.vl {
	border-left: 1px solid lightgray;
	height: 0.75rem;
}
.page-cards > div, .page-cards > a {
	font-size: smaller;
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	flex-direction: column;
}

.page-cards fa-icon {
	color: var(--main-blue-color);
}

.page-cards img {
	height: 70px;
}

.page-cards > div:not(:first-child), .page-cards > a:not(:first-child){
	border-left: 2px solid var(--box-border-color);
	border-bottom: 2px solid var(--box-border-color);
}
.page-cards > div:first-child, .page-cards > a:first-child {
	border-bottom: 2px solid var(--box-border-color);
}

.page-cards > div:hover, .page-cards > div.active , .page-cards > a:hover, .page-cards > a.active{
	color: var(--main-blue-color);
}
 .page-cards > div.active, .page-cards > a.active {
	border-bottom:3px solid var(--main-blue-color);
}
.size-35x35{
	width: 35px;
	height: 35px;
}
.size-25x25{
	width: 25px;
	height: 25px;
}
.size-20x20{
	width: 20px;
	height: 20px;
}
.size-15x15{
	width: 20px;
	height: 20px;
}
.opacity-50{
	filter: opacity(0.5);
}
.topnav a:hover {
	border-bottom: 3px solid var(--main-blue-color);
}

.topnav a.active {
	border-bottom: 3px solid var(--main-blue-color);
	color: var(--main-black-color) !important;
}
.light-grey-border{
	border: 1px solid var(--main-border-color);
}
.input-icon {
	float: right;
	margin-right: 0.5em;
	margin-top: -1.6em;
	position: relative;
	z-index: 2;
	color: black;
	cursor: pointer;
}
.password-eye{
	float: right;
	margin-right: 0.5em;
	margin-top: -1.7em;
	position: relative;
	z-index: 2;
	color: black;
	cursor: pointer;
}
.tooltip-inner {
	color: #004085;
	background-color: #cce5ff;
	border-color: #b8daff;
}
.scroll-x-auto {
	overflow-x: auto;
}
.chartLegend {
	float: left;
	height: 13px;
	width: 26px;
}
.red-bg {
	background: #ffcccb!important;
}
.orange-bg {
	background: #ffe6cb!important;
}
.green-bg {
	background: #d2ffcb!important;
}

@media (max-width:767px){
	.mapbox-widget {
		margin-left:-1.5rem;
		margin-right:-1.5rem;
	}
}

@media (max-width:1080px){
	.mapbox-widget {
		max-width: 800px;
		margin: 0 auto;
		height: 500px;
	}
}

.bg-main-black-color {
	background-color: var(--main-black-color) !important;
}

.small, small {
	font-size: 80%;
}

.charglink-logo {
	height: 100px;
	width: auto;
}

.brm-logo {
	height: 100px;
	width: auto;
}

.validated-field {
	position: relative;
}

.invalid-tooltip {
	display: block;
}

.study-logo {
	height: 50px;
	width: auto;
	max-width: 150px;
}

.cursor-pointer {
	cursor: pointer;
}

.notValidOption {
	color: red;
}

.link-text {
	cursor: pointer;
	color: #007bff;
}

.link-text:hover {
	text-decoration: underline;
}

.no_decoration_link {
	text-decoration: none;
	color: var(--main-black-color);
}

.card-container {
	overflow: auto;
}

.public-pages-bg {
	background-color: var(--public-pages-bg);
	margin: 0;
	min-height: 100vh;
	max-width: 100vw;
	overflow-x: hidden;
}

/* 120px is the height of the header and footer */
.full-height {
	min-height: calc(100vh - 120px);
}

.fit-width {
	max-width: 100vw;
}

.green-btn {
	background-color: #95C93D !important;
}

/* before login page from style */
.before-login-form {
	background-color: #fff;
	width: 500px;
	padding: 50px 40px 50px;
	border-radius: 10px;
}

.before-login-form .submit-area {
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.before-login-form .submit-area p {
	font-size: 14px;
	color: #000;
	margin: 2px;
}

.before-login-form .submit-area button {
	width: 130px;
	border-radius: 5px;
	background-color: #95C93D;
	color: #fff;
	font-size: 16px;
}

@media (max-width: 769px) {
	.before-login-form {
		width: 400px;
		padding: 40px 30px 40px;
	}
}

@media (max-width: 426px) {
	.before-login-form {
		width: 350px;
		padding: 40px 30px 40px;
	}
}
